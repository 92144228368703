.responsive-burger-menu {
  position: fixed !important;
  z-index: 20;
  top: 60px;
  background-color: white;
  width: 100vw;
}

.subnavbar {
  height: 48px;
  a,
  button {
    color: white;
    height: 25px;
  }
}

.subnavbar-christmas-link {
  color: #00ffbc !important;
}
.subnavbar-christmas-link:hover {
  color: darken(#00ffbc, 20%) !important;
}

.subnavbar-mes-bd-button {
  height: 16px !important;
}

.subnavbar-mes-bd-button-round {
  border-radius: 12px !important;
}
