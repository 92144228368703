@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.btn-add-to-cart {
  color: #7aca22 !important;
  border: 1px solid #7aca22 !important;
}
.btn-add-to-cart-yellow {
  color: #ffc107 !important;
  border: 1px solid #ffc107 !important;
}

@media (max-width: 768px) {
  .btn-add-to-cart {
    color: #ffffff !important;
    border: 1px solid #7aca22 !important;
    background-color: #7aca22 !important;
  }
  .btn-add-to-cart-yellow {
    color: #ffffff !important;
    border: 1px solid #ffc107 !important;
    background-color: #ffc107 !important;
  }
}

.btn-add-to-cart:disabled,
.btn-add-to-cart-yellow:disabled {
  color: $gray-400 !important;
  background-color: transparent !important;
  border-color: $gray-400 !important;
}

.btn-add-to-cart:hover:not(:disabled) {
  color: white !important;
  background-color: #7aca22 !important;
  border-color: #7aca22 !important;
}

.btn-add-to-cart-yellow:hover:not(:disabled) {
  color: white !important;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
