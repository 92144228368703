.Comment-avatar-div {
  flex: 0 0 60px !important;
}

.comment-avatar-img {
  width: 45px;
}

.Comment-content {
  white-space: pre-wrap;
}

.pl30 {
  padding-left: 30px !important;
}

.inlineLikeZone > div {
  width: 200px !important;
  padding-right: 10px;
}

.inlineLikeZone div {
  display: inline !important;
}

@media screen and (min-width: 600px) {
  .comment-shift {
    margin-start: 45px !important;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
