$fb-color: #3e5a9c;
$google-color: #dc4437;

.bb-btn-guest {
  font-size: 0.9rem !important;
  height: 3rem;
}

.bb-btn-fb {
  background-color: $fb-color !important;
  border-color: $fb-color !important;
  color: white !important;
  font-size: 0.9rem !important;
  height: 3rem;
}
.bb-btn-fb:hover {
  background-color: darken($fb-color, 10%) !important;
  border-color: darken($fb-color, 10%) !important;
}

.bb-btn-fb:active {
  background-color: darken($fb-color, 20%) !important;
  border-color: darken($fb-color, 20%) !important;
}

.bb-btn-google {
  background-color: $google-color !important;
  border-color: $google-color !important;
  color: white !important;
  font-size: 0.9rem !important;
  height: 3rem;
}
.bb-btn-google:hover {
  background-color: darken($google-color, 10%) !important;
  border-color: darken($google-color, 10%) !important;
}

.bb-btn-google:active {
  background-color: darken($google-color, 20%) !important;
  border-color: darken($google-color, 20%) !important;
}

.reverse {
  transform: scale(-1, 1);
}

.input-container {
  height: 3rem !important;
  background-color: #f6f6f6 !important;
  border: none !important;
}


.circle-with-icon {
  min-height: 4rem;
  min-width: 4rem;
  max-height: 4rem;
  max-width: 4rem;
}