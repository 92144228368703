.ul-no-indent {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0.3rem;
}

.text-small {
  font-size: 0.6em !important;
}

.badge-padding {
  border-radius: 0.2rem !important;
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}
