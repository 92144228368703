@import '../../custom.scss';

.nart-container {
  min-width: 0;
  background-color: white;
  flex: 1;
}

@media (min-width: 1281px) {
  .nart-container {
    max-width: 1280px;
  }
}

.nart-category-color-all .nart-bg-highlight {
  background-color: map-get($theme-colors, 'nart-color') !important;
  color: map-get($theme-colors, 'white');
}
.nart-category-color-bd .nart-bg-highlight {
  background-color: map-get($theme-colors, '9eart-bd') !important;
  color: map-get($theme-colors, 'white');
}
.nart-category-color-comics .nart-bg-highlight {
  background-color: map-get($theme-colors, '9eart-comics') !important;
  color: map-get($theme-colors, 'white');
}
.nart-category-color-mangas .nart-bg-highlight {
  background-color: map-get($theme-colors, '9eart-mangas') !important;
  color: map-get($theme-colors, 'white');
}
.nart-category-color-jeunesse .nart-bg-highlight {
  background-color: map-get($theme-colors, '9eart-jeunesse') !important;
  color: map-get($theme-colors, 'white');
}
.nart-category-color-webtoon .nart-bg-highlight {
  background-color: map-get($theme-colors, '9eart-webtoon') !important;
  color: map-get($theme-colors, 'white');
}
.nart-category-color-all .nart-text-highlight {
  color: map-get($theme-colors, 'nart-color');
}
.nart-category-color-bd .nart-text-highlight {
  color: map-get($theme-colors, '9eart-bd');
}
.nart-category-color-comics .nart-text-highlight {
  color: map-get($theme-colors, '9eart-comics');
}
.nart-category-color-mangas .nart-text-highlight {
  color: map-get($theme-colors, '9eart-mangas');
}
.nart-category-color-jeunesse .nart-text-highlight {
  color: map-get($theme-colors, '9eart-jeunesse');
}
.nart-category-color-webtoon .nart-text-highlight {
  color: map-get($theme-colors, '9eart-webtoon');
}
