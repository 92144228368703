.nart-newsletter-optin {
  background-image: url(/assets/9eart/banner_newsletter.webp);
  object-fit: cover;
  align-self: center;
}
.no-webp .nart-newsletter-optin {
  background-image: url(/assets/9eart/banner_newsletter.jpg);
  object-fit: cover;
  align-self: center;
}
