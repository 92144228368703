.add-to-collection-zone {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 35px;
  height: 35px;
  background-color: #7aca2222;
  border: 1px solid #7aca22;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.add-to-collection-zone.force-show {
  opacity: 1;
}
