.bubble-concept-box {
  height: 250px;
  width: 250px;
}

.bubble-concept-background {
  background-size: cover;
  background-position: center;
  background-image: url(/assets/home/concept_banner_black.webp);
  @media (max-width: 768px) {
    background-position: left;
    background-image: url(/assets/home/concept_banner_black_mobile.webp);
  }
}
.no-webp .bubble-concept-background {
  background-image: url(/assets/home/concept_banner_black.jpg);
  @media (max-width: 768px) {
    background-image: url(/assets/home/concept_banner_black_mobile.jpg);
  }
}
