.checkbox-container {
  cursor: pointer;
  user-select: none;
}

.hidden-checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.bb-checkbox {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transition: all 100ms;
  border: 1px solid;
}
