.text-twitter {
  color: #1da1f2;
}
.text-instagram {
  color: #ef6028;
}
.text-facebook {
  color: #3b5998;
}

.author-social-text {
  font-size: 1rem;
  @media (max-width: 768px) {
    font-size: 1.35rem;
  }
}

.author-subscribe-button {
  height: 50px;
  max-width: 260px;
}

.author-navbar-highlight-bar {
  height: 7px;
  background-color: transparent;
}

.author-related-image {
  height: 70px;
  width: 70px;
}
