.infinity-black-overlay {
  background-color: rgba(0, 0, 0, 0.9);
  background-image: url(assets/infinity/background_pub_infinityweb.webp);
  background-position: center;
}
.no-webp .infinity-black-overlay {
  background-image: url(assets/infinity/background_pub_infinityweb.jpg);
}

.infinity-reduce-line-height {
  line-height: 2rem;
}

.infinity-card-logo {
  height: 30px;
}

.no-wrap {
  white-space: nowrap;
}
