.key-value-background {
  background-image: url(../../../assets/landings/background_key_value.webp);
  background-size: cover;
}
.no-webp .key-value-background {
  background-image: url(../../../assets/landings/background_key_value.jpg);
  background-size: cover;
}

.key-value-mac-phone {
  width: 100%;
  height: auto;
}
