.print-cell-full-height-container {
  height: 100%;
}

.print-cell {
  border: solid 1px transparent;
  background-color: white;
}

.print-cell-selected {
  border: solid 1px #7aca21;
  background-color: #f5fbef;
}

.print-list-img {
  height: 8rem;
}
