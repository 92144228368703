.article-white-title {
  height: 2.4rem;
  line-height: 1.2rem;
  overflow: hidden;
}
.article-white-title-big {
  font-size: 1.6rem;
  line-height: 1.3em;
  padding: 8px 0px 8px 0px;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 1rem;
    height: 2.4rem;
    line-height: 1rem;
    overflow: hidden;
  }
}

@media (max-width: 768px) {
  .nart-image-cover {
    min-width: 100px !important;
    max-width: 100px !important;
  }
}
