.review-image {
  width: 45px;
  height: 45px;
  object-fit: cover;
}
.verified-purchase-badge {
  padding: 4px 5px 2px 5px;
  border-radius: 4px;
  background-color: #ff0080;
  color: white !important;
}
