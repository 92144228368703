@media (max-width: 768px) {
  .image-container {
    object-fit: cover;
  }
  .placeholder-container {
    height: 150px;
  }
}

@media (min-width: 768px) {
  .image-container {
    max-height: 390px;
    object-fit: cover;
  }
  .placeholder-container {
    height: 390px;
  }
}

.banner-anim-enter {
  opacity: 0;
}
.banner-anim-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.banner-anim-exit {
  opacity: 1;
}
.banner-anim-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.container-slim {
  max-height: 230px;
}

@media (max-width: 768px) {
  .banner-title {
    max-width: 75%;
    font-size: 1.7rem;
    line-height: 2rem;
  }
}
@media (min-width: 768px) {
  .banner-title {
    font-size: 2.5rem;
    line-height: 3.3rem;
  }
}
