.search-result-zone {
  background-color: white;
  position: fixed;
  z-index: 1000;
  left: 0px;
  width: 100vw;
}

@media (max-width: 992px) {
  .search-result-zone {
    overflow: scroll;
    height: 85vh;
  }
}
