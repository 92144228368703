.sidebar-optin-background {
  background: #fcc930;
  background: linear-gradient(
    -45deg,
    #fcc930 0%,
    #fcc930 25%,
    #fcda6e 25%,
    #fcda6e 75%,
    #fcc930 75%
  );
  height: 146px;
}

.big-text {
  font-size: 18px;
  line-height: 20px;
}
@media (min-width: 768px) {
  .big-text {
    font-size: 24px;
    line-height: 26px;
  }
}

.p18p {
  font-size: 18px;
  line-height: 26px;
}

.light-icon {
  width: 70px;
  height: auto;
}

.valid-circle {
  min-width: 60px;
  min-height: 60px;
  width: 60px;
  height: 60px;
}
