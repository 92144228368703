.close-button-item-card {
  position: relative;
  right: 0;
}
.wishlist-heart {
  margin-top: 2px;
}
.quantity-input {
  width: 40px !important ;
  text-align: center !important ;
  padding-bottom: 1px !important ;
}