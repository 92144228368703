.reward-icon-badge {
  width: 18px;
  height: 18px;
  position: relative;
  bottom: 1px;
}
.reward-badge {
  padding: 5px 7px 2px 7px;
  border-radius: 4px;
  background-color: #ff0080;
  color: white !important;
}
