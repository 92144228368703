.user-image {
  object-fit: cover;
  width: 8rem;
  height: 8rem;
}

.header-stats-box {
  height: 6rem;
  line-height: 1.2rem;
}
@media (max-width: 768px) {
  .header-stats-box {
    height: 4.5rem;
    line-height: 1.2rem;
  }

  .user-profile-name-container {
    padding: 10px 0px 10px 0px;
    align-items: center;
    text-align: center;
  }

  .user-profile-name {
    justify-content: center;
  }
}

.my-collection-mosaic-item {
  background-position-y: top;
  background-position-x: center;
  background-size: cover;
  padding-top: 129%;
}

.collection-serie-card-gradient {
  background: rgb(48, 48, 48);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.34637605042016806) 36%,
    rgba(0, 0, 0, 0.7161239495798319) 55%,
    rgba(0, 0, 0, 0.8869922969187675) 100%
  );
}
