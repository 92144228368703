.text-medium {
  font-size: 15px;
}
.app-store-button {
  height: 60px;
}
.social-icon {
  width: 30px;
}
.social-icon-small {
  width: 25px;
}

.footer-text {
  color: #e2e2e2;
}

.footer-title {
  color: white;
  padding-bottom: 10px;
  @media (max-width: 992px) {
    padding-top: 15px;
  }
}

.bb-footer {
  a,
  p,
  button {
    color: #e2e2e2;
  }
}

.footer-bubble-logo {
  width: 170px;
}

.avis-verifies-footer-area {
  padding-bottom: 13px;
  width: 100%;
}
