.bookseller-partner-image {
  object-fit: contain;
}

.bookseller-partner-btn {
  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
}

.home-store-partner-background {
  background-size: cover;
  background-position: center;
  background-image: url(/assets/home/banner_pink.webp);
  @media (max-width: 768px) {
    background-position: left;
    background-image: url(/assets/home/banner_pink_mobile.webp);
  }
}
.no-webp .home-store-partner-background {
  background-image: url(/assets/home/banner_pink.jpg);
  @media (max-width: 768px) {
    background-image: url(/assets/home/banner_pink_mobile.jpg);
  }
}
