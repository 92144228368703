.btn-grey:not(:hover) {
  color: grey !important;
  border-color: lightgray !important;
  background-color: transparent !important;
}

.btn-reviews {
  height: 3rem;
}

.btn-infinity:not(:hover) {
  border-color: #303030;
  color: white !important;
  background-color: #303030 !important;
}

.btn-infinity:hover .btn-infinity-yellow {
  color: #303030;
}
.btn-infinity-yellow {
  color: #fbc107;
}

.agenda-background {
  background-size: cover;
  background-position: center;
  background-image: url(/assets/home/banner_purple.webp);
  @media (max-width: 768px) {
    background-image: url(/assets/home/banner_purple_mobile.webp);
  }
}
.no-webp .agenda-background {
  background-image: url(/assets/home/banner_purple.jpg);
  @media (max-width: 768px) {
    background-image: url(/assets/home/banner_purple_mobile.jpg);
  }
}
