@import '../../custom.scss';

@media (min-width: 768px) {
  .padding-first-two:nth-child(-n + 2) {
    padding-bottom: 32px !important;
  }
}
@media (min-width: 768px) {
  .padding-first-three:nth-child(-n + 3) {
    padding-bottom: 32px !important;
  }
}

.nart-colored-macaron {
  height: 20px;
  width: 20px;
  position: relative;
  bottom: -4px;
}

.paper-body a.bg-white:hover {
  background-color: map-get($theme-colors, 'white') !important;
}
