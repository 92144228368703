.header-no-separator {
  border-bottom: none !important;
  /* height: 1rem; */
}

.reservation-title {
  max-width: 60%;
}

.horizontal-scroll {
  overflow-y: auto;
}

.modal-reset-overflow .modal {
  overflow-y: initial !important;
}

@media (max-width: 768px) {
  .availabilities-title {
    line-height: 1.8rem;
  }
}

.modal-body-scroll {
  height: 70vh;
  overflow-y: auto;
}

.modal-full-height-container {
  height: 100%;
}

.infinity-modal-separator {
  height: 3px;
  width: 70%;
}

.modal-infinity-background {
  background-color: rgb(41, 41, 41);
  background-image: url(assets/infinity/background_modale_infinity.webp);
}

.no-webp .modal-infinity-background {
  background-image: url(assets/infinity/background_modale_infinity.jpg);
}

.mobile-spacer {
  height: 75px;
}
