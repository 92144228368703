.labeled-text-input {
  border: none !important;
  background-color: #f5f5f5 !important;
  height: 2.7rem !important;
}

::placeholder { 
  color: #e0e0e0 !important;
  opacity: 1; /* Firefox */
} 

.labeled-text-input-white {
  border: none !important;
  background-color: 'white' !important;
  height: 2.7rem !important;
}

.no-end-border {
  border-top-end-radius: 0rem !important;
  border-bottom-end-radius: 0rem !important;
}
.no-start-border {
  border-top-start-radius: 0rem !important;
  border-bottom-start-radius: 0rem !important;
}

.input-small-text {
  font-size: 11px !important;
}
