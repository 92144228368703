.ksp-image {
  height: 100%;
}
.ksp-image-zone {
  height: 50px;
}
.ksp-item {
  min-width: 200px;
}

.ksp-background {
  background-color: #00ffbc;
}
