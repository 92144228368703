.top-image-container {
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: -25rem;
  background-image: url(/assets/img_isometric_header_overlay.webp);
}

.no-webp .top-image-container {
  background-image: url(/assets/img_isometric_header_overlay.png);
}

.top-rank {
  width: 60px;
}

.score-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  width: 32px;
  height: 32px;
  border-radius: 0px 3px 0px 3px;
  position: absolute;
  top: 0px;
  right: 8px;
}
.score-panel-serie {
  right: 0px;
}

.semi-bold {
  font-weight: 600;
}

.position-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0px;
  left: 5px;
}

.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.separator-absolute {
  left: 50% !important;
  height: 100% !important;
}

@media (min-width: 960px) {
  .w-lg-80 {
    width: 83% !important;
  }
}
