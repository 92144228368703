.header-quiz {
  min-width: 10rem;
  font-size: 12px;
}
.bubble-logo {
  width: 120px;
}

.bubble-logo-small {
  width: 100px;
}

.infinity-logo {
  height: 1.5rem;
}

.lock-logo {
  height: 2rem;
}

.header-icon {
  height: 1.6rem;
}

.header-burger-icon {
  height: 1.5rem;
}

.comicsblog-logo {
  width: 109px;
}

.website-header .navbar {
  @media (max-width: 992px) {
    padding: 0 !important;
    margin: 0 !important;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.burger-menu-button {
  height: 60px;
  width: 60px;
}

.header-container {
  z-index: 1000 !important;
}

.nart-logo {
  height: 47px;
  @media (max-width: 992px) {
    height: 40px;
  }
}

.nart-bubble-logo-small {
  width: 25px;
}
.nart-logo-small {
  width: 20px;
}

.p-2-5 {
  padding: 0.5rem 0.62rem !important;
}
.cart-item-amount-container {
  background-color: #fde7e0;
  border-radius: 100%;
  color: #f75f2b;
  margin-left: 0px;
  position: relative;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  top: -7px;
  font-weight: bold;
  font-size: 0.8rem;
}

.cart-item-amount {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
@media (max-width: 768px) {
  .header-hidden {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 200ms;
  }
}

.bookseller-icon {
  height: 28px;
}

.nart-background {
  background-color: black;
  background-image: url(/assets/9eart/background_nart.webp);
  background-repeat: no-repeat;
  background-position: top;
}
.no-webp .nart-background {
  background-color: black;
  background-image: url(/assets/9eart/background_nart.jpg);
  background-repeat: no-repeat;
  background-position: top;
}

.paper-nav-element {
  border-radius: 25px;
}

.paper-section-element-bar {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  bottom: -16px;
  width: 80%;
  height: 4px;
}

.nart-burger-button {
  transition: 0.3s;
}

.nart-burger-button-rotated {
  transform: rotate(90deg);
}

.nart-burger-icon {
  height: 30px;
}
