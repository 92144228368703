.tag-container {
  background-color: #4a91e215;
  font-weight: 600;
}

.tag-container-grey {
  background-color: #7878781f;
  font-weight: 400;
}

.tag-placeholder {
  height: 29.61px;
}
.tag-placeholder-text {
  opacity: 0.1;
}
