.store-background {
  background: rgb(48, 48, 48);
}

.store-page-logo {
  width: 6rem;
  border-radius: 6rem;
}

.store-page-showcase-image {
  height: 30rem;
}

.store-page-keypoint {
  width: 3rem;
}

.iso-placeholder {
  background-image: url(assets/img_isometric_header_overlay.webp);
}

.no-webp .iso-placeholder {
  background-image: url(assets/img_isometric_header_overlay.png);
}
