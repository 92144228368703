.detail-type {
  width: 14rem;
}

.detail-select {
  border: none;
  margin-left: -4px;
}

.detail-summary {
  white-space: pre-line;
}
