.global-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2147483001; /* intercom has z-index `2147483000` */
}
.global-overlay-enter {
  opacity: 0;
}
.global-overlay-enter-active {
  opacity: 1;
  transition: opacity 100ms;
}
.global-overlay-exit {
  opacity: 1;
}
.global-overlay-exit-active {
  opacity: 0;
  transition: opacity 100ms;
}
.over-global-overlay {
  position: relative;
  z-index: 2147483002;
}
