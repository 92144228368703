.menu-icon-infinity {
  margin-top: -8px;
}

.no-chevron::before {
  content: none !important;
}

.profile-navbar-dropdown-chevron {
  right: -7px;
  top: 2px;
}
