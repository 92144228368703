.article-loading {
  height: 0;
  padding-bottom: 40%;
  background-color: #303030;
}
.up-2 {
  margin-top: -2px !important;
}

.article-header {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.article-tag {
  height: 40px !important;
  background-color: #def1f9;
  color: #59bae1;
}

.article-header-title {
  font-size: 2.2rem;
  @media (max-width: 500px) {
    font-size: 1.8rem;
  }
}
.article-image {
  width: 100%;
  max-height: 465px;
  object-fit: cover;
  @media (max-width: 500px) {
    min-height: 220px;
  }
}
.article-header-title {
  max-width: 800px;
}
.mega-rounded {
  border-radius: 16px;
}

.article-headline {
  font-size: 1.3rem;
  margin-top: 0;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}

.article-content {
  p,
  ul,
  .article-paragraphe {
    font-size: 1.11rem;
    margin-top: 0;
    margin-bottom: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    @media (max-width: 500px) {
      font-size: 1.1rem;
    }
  }
  blockquote {
    margin: 2.6rem 4rem;
    border-left: 2px solid #b5b5b5;
    padding-left: 1rem;
  }
  blockquote p {
    font-size: 1.6rem;
    color: #909090;
    @media (max-width: 500px) {
      font-size: 1.5rem;
    }
  }
  a {
    transition: 0.1s;
    color: #303030;
    border-bottom: 1px solid #ff0080;
    text-decoration: none;
  }
  a:hover {
    color: #ff0080;
    text-decoration: none;
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 2.2rem;
    margin-top: 3.2rem;
    line-height: 1.4;
    @media (max-width: 500px) {
      font-size: 1.9rem;
    }
  }
  h3 {
    font-size: 1.6rem;
    margin-bottom: 2.6rem;
    margin-top: 2.8rem;
    line-height: 1.5;
    @media (max-width: 500px) {
      font-size: 1.5rem;
    }
  }
  h4 {
    font-size: 1.1rem;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
    line-height: 1.5;
    @media (max-width: 500px) {
      font-size: 1.1rem;
    }
  }
  h2 a,
  h3 a,
  h4 a {
    font-weight: 600;
  }
  figure {
    position: relative;
    margin: 3rem auto;
    img {
      max-width: 100%;
      height: auto;
    }
    video {
      max-width: 100%;
      height: auto;
    }
  }
  figcaption {
    display: none;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    a {
      color: #ff0080;
    }
  }
  figure:hover figcaption {
    display: block;
  }
  iframe {
    max-width: 100%;
  }

  $screen-switch: 600px;
  .alignleft {
    text-align: center;
  }
  @media (min-width: $screen-switch) {
    .alignleft {
      float: left;
      margin-top: 0;
      margin-right: 3rem;
    }
  }
  .alignright {
    text-align: center;
  }
  @media (min-width: $screen-switch) {
    .alignright {
      float: right;
      margin-top: 0;
      margin-left: 3rem;
    }
  }

  .aligncenter {
    text-align: center;
  }

  .size-large:not(.is-resized) img {
    width: 300px;
    height: auto;
  }
  .size-full:not(.is-resized) img {
    width: 100%;
    height: auto;
  }

  .wp-block-spacer {
    height: 0px !important;
  }
  .wp-block-separator {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #e2e2e2;
  }

  .nart-chapter img {
    max-width: 100% !important;
    height: auto !important;
  }
  .nart-chapter {
    padding-left: 5%;
    padding-right: 5%;
  }
  .nart-chapter p {
    font-size: 1.05rem !important;
    font-weight: 300 !important;
  }
  .nart-video {
    margin-top: 0;
    margin-bottom: 2rem;
    text-align: center;
  }
}
