@import '../../custom.scss';

.album-collection-ctas {
  right: 0px;
  top: 10px;
  z-index: 1;
}

.old-release-container {
  bottom: 0;
  right: 0;
  padding-top: 3px;
  padding-bottom: 1px;
  opacity: 0.95;
}

.release-container {
  position: absolute;
  border-radius: 0px 0px 5px 0px;
  top: 0;
  left: 0;
  padding-top: 1px;
  padding-bottom: 1px;
  opacity: 0.9;
}

.bottom-right-tags-container {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.album-card-tag-container {
  padding: 3px 10px 2px 10px;
  border-top-left-radius: 10px;
}

.tag-container-promotion {
  background-color: rgba(map-get($theme-colors, 'pink'), 0.7);
}
.tag-container-editor-choice {
  background-color: rgba(map-get($theme-colors, 'purple'), 0.7);
}

.album-card-image {
  width: 100%;
  aspect-ratio: 0.7;
  object-fit: cover;
}

.album-card-image-placeholder {
  background-color: rgba(map-get($theme-colors, 'secondary'), 0.2);
}

.album-card-placeholder-top-bars {
  height: 43px;
}
.album-card-placeholder-spacer {
  height: 20px;
}

.album-card-wishlist-icon {
  position: relative;
  bottom: 2px;
}

.add-to-collection-container {
  z-index: 10;
}

.album-card:hover .display-on-parent-hover {
  opacity: 1;
}

.album-card-title {
  line-height: 1.2rem;
  max-height: 2.4rem;
  overflow: hidden;
}

.album-card-placeholder-bar {
  height: 16px;
  opacity: 0.2;
}
