.main-category-image {
  max-width: 150px;
  object-fit: contain;
  align-self: center;
}
.main-category-image-small {
  max-width: 110px;
  object-fit: contain;
  align-self: center;
  margin: 0px 20px 0px 20px
}

.main-category-container {
  border: 1px solid #ededed;
  align-items: center;
  padding: 1.5rem
}
@media (max-width: 768px) {
  .main-category-container {
    padding: .5rem !important;
  }

  .mobile-horizontal-scroll.row {
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
  }
}
