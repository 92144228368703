.burger-menu {
  transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  position: fixed;
  height: 100%;
  min-width: 100%;
  z-index: 90000;
  overflow-y: scroll;
  top: 0px;
  color: white;
  padding-bottom: 100px;
}

.burger-menu:not(.show) {
  transform: translate(-100%);
}

.burger-menu.show {
  transform: translate(0);
}

.burger-menu-header {
  height: 70px;
}

.burger-menu a {
  text-decoration: none;
  color: white;
}

.burger-menu-link-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.burger-menu-christmas {
  color: #00ffbc !important;
}
