.article-brief-card-highlight-line {
  min-width: 6px;
  max-width: 6px;
}

.article-brief-card-title {
  font-size: 1.12rem;
  line-height: 1.25rem;
  max-height: 40px;
  overflow: hidden;
}

.article-brief-card {
  height: 80px;
}
