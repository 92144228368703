.dropdown-container {
  margin-top: -1px;
  z-index: 999;
  position: absolute;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 150ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms;
}
