// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';

// 2. Include any default variable overrides here
$font-family-sans-serif: 'Boing', 'Avenir', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-size-base: 0.95rem;

$enable-negative-margins: true;

// $enable-cssgrid: true;
// $enable-grid-classes: true;

$border-color: #dee2e6;
$modal-footer-border-width: 1px;

$dropdown-link-active-bg: #ff0080;

$primary-color: #ff0080;
$green-color: #7aca22;

$link-decoration: underline;

$badge-padding-y: 0.5em;
$badge-padding-x: 0.6em;
$badge-font-size: 0.75rem;
$badge-font-weight: 400;

$grid-gutter-width: 16px; // default size is 32px

$modal-header-border-width: 0px;

$label-margin-bottom: 0px;

$table-bg: #f6f6f6;

$nav-tabs-border-width: 0px;
$nav-tabs-link-active-bg: #f6f6f6;

$container-max-widths: (
  xxl: 1450px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1450px,
);

$link-color: #787878;
$link-hover-color: darken($link-color, 15%);

$body-color: #303030;

$theme-colors: (
  'primary': #4a90e2,
  'success': #7aca22,
  'warning': #f6c343,
  'danger': #dc3545,
  'bubble-color': #ff0080,
  'nart-color': #ff0080,
  'white': white,
  'grey': #303030,
  'blacker': #171717,
  'infinity': #fcca30,
  'facebook': #3e5a9c,
  'twitter': #02aaec,
  'google': #dc4437,
  'secondary': #787878,
  'placeholder': #e0e0e0,
  '9eart-bd': #59bae1,
  '9eart-mangas': #d9bc35,
  '9eart-comics': #97c055,
  '9eart-jeunesse': #e15961,
  '9eart-webtoon': #a559e1,
  'pink': #ff0080,
  'green': #00ffbc,
  'purple': #904aff,
  '9eart-from-9eart': #ff0080,
  '9eart-blog': #a2a2a2,
);

$btn-close-color: 'red';

// 3. Include remainder of required Bootstrap stylesheets
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

// 3.5. Include maps removals https://getbootstrap.com/docs/5.2/customize/sass/#remove-from-map
$theme-colors: map-remove($theme-colors, 'info', 'light', 'dark');

// 4. Include any default map overrides here
$custom-spacers: (
  6: 6rem,
  paper: 2.5rem,
);

$spacers: map-merge($spacers, $custom-spacers);

// 5. Include remainder of required parts
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 6. Optionally include any other parts as needed
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
// @import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';
// @import '~bootstrap/scss/breadcrumb';
// @import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
// @import '~bootstrap/scss/alert';
// @import '~bootstrap/scss/progress';
// @import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
// @import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
// @import '~bootstrap/scss/offcanvas';
// @import '~bootstrap/scss/placeholders';

// Helpers
@import '~bootstrap/scss/helpers';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '~bootstrap/scss/utilities/api';

// @import "~bootstrap/scss/bootstrap";

// 8. Add additional custom code here

// we do use fontawesome as css because it ads ~15KB of JS (gzip version) if we use the JS
// and .svg integration with `react-fontawesome`
// Font awesome
@import '~@fortawesome/fontawesome-free/css/all.css';

@import 'index.scss';
