.bdcat-button {
  position: fixed;
  bottom: 0px;
  left: 0px;
}

.bdcat-button-close {
  margin-bottom: -18px;
  right: 0px;
}

.bdcat-button-icon {
  height: 45px;
}
