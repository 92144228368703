.Row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.Item {
  width: 282px;
  height: 525px;
  display: inline-block;
  flex-direction: row;
  padding: 0px 8px 0px 8px;
  justify-content: start;
  align-items: center;
}

.date-transition {
  transition: color 0.3s;
}

.speech-bubble {
  background-color: #ffffff;
  border-bottom-end-radius: 7px;
  border-bottom-start-radius: 7px;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  right: 0;
  top: 40%;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-start-color: #ffffff;
  border-end: 0;
  margin-top: -8px;
  margin-end: -8px;
}

.agenda-top-spacer {
  top: 100px !important;
}
