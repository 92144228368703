.line-height-36 {
  line-height: 2.25rem;
}

.line-height-30 {
  line-height: 1.875rem;
}

.line-height-24 {
  line-height: 1.5rem;
}

.gfp-selected {
  font-weight: bold;
  color: #7aca22 !important;
  border: 1px solid #7aca22 !important;
  background-color: #f5fbef !important;
}

.gift-card-price {
  color: #303030;
  text-decoration: none;
  background-color: white;
  border: 1px solid lightgrey;
}

.gift-card-icon {
  width: 20px;
}
