.home-mobile-app-background {
  background-size: cover;
  background-position: center;
  background-image: url(/assets/home/banner_phone.webp);
  @media (max-width: 768px) {
    height: 800px !important;
    background-position: center;
    background-image: url(/assets/home/banner_phone_mobile.webp);
  }
}
.no-webp .home-mobile-app-background {
  background-image: url(/assets/home/banner_phone.jpg);
  @media (max-width: 768px) {
    background-image: url(/assets/home/banner_phone_mobile.jpg);
  }
}
