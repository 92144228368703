.link-bb:hover {
  opacity: 0.5;
  transition: 0.2s;
}
.no-transition.link-bb:hover {
  opacity: 0.5;
  transition: none;
}

.link-bb {
  transition: 0.2s;
}

.no-transition.link-bb {
  transition: none;
}

.disabled-bb {
  opacity: 0.5;
}
