.home-infinity-background {
  background-size: cover;
  background-position: center;
  background-image: url(/assets/home/banner_yellow.webp);
  @media (max-width: 768px) {
    background-position: left;
    background-image: url(/assets/home/banner_yellow_mobile.webp);
  }
}
.no-webp .home-infinity-background {
  background-image: url(/assets/home/banner_yellow.jpg);
  @media (max-width: 768px) {
    background-image: url(/assets/home/banner_yellow_mobile.jpg);
  }
}
