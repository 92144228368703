.transparent-icon-container {
  background-color: white !important;
  outline: none !important;
  box-shadow: none !important;
}
.input-no-start-border {
  border-left: none !important;
}
.input-no-end-border {
  border-right: none !important;
}
.input-transparent-container {
  background-color: transparent !important;
}
.input-medium-height {
  height: 3rem !important;
}
