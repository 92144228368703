.promotions-image-container {
  background-repeat: no-repeat;
  overflow-y: show;
  background-position-x: center;
  background-position-y: -25rem;
  background-image: url(/assets/img_isometric_header_overlay.webp);
}

.no-webp .promotions-image-container {
  background-image: url(/assets/img_isometric_header_overlay.png);
}

@media (max-width: 768px) {
  .promotion-checkbox {
    width: 10rem;
  }
}
