.modal-tag-row {
  background-color: #4a91e215;
  padding: 10px 10px;
  font-weight: 600;
}

.plus-icon {
  width: 21px;
  height: 21px;
}

.plus-icon-text {
  color: white;
  font-size: 21px;
  padding-top: 2px;
}

.flag-icon {
  opacity: 0.3;
}
