.info-modal-row-icon {
    width: 35px;
    padding-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .info-modal-row-icon-image {
    width: 25px;
  }
  
  .info-modal-text-end {
    text-align: right;
  }
  