.top-horizontal-card-bg {
  position: relative;
  overflow: hidden;
}
.top-horizontal-card-bg::before {
  content: '';
  top: -10%;
  left: -10%;
  width: 120%;
  height: 120%;
  position: absolute;
  background-image: inherit;
  background-size: cover;
  filter: blur(5px);
}
.top-horizontal-card-bg-inside {
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
}
