.transparent-container {
  background-color: transparent !important;
  box-shadow: none !important;
}

.home-search-bar {
  max-width: 800px;
}

.placeholder-text {
  position: absolute;
  left: 1rem;
  font-size: 13px;
  color: #cccccc;
}
.search-clear-icon {
  font-size: 18px;
  padding-right: 15px;
}

.search-background {
  background-color: #404040;
}

.home-search-subtext {
  display: none;
}
@media (min-width: 1300px) {
  .home-search-subtext {
    display: inline-block;
  }
}
