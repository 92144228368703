.nart-burger-menu-container {
  transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  position: fixed;
  z-index: 90000;
  top: 72px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.nart-burger-menu-content {
  max-height: 80%;
  width: 80%;
  overflow-y: scroll;
  background-color: #f8f8f8;
}

.nart-burger-menu-container:not(.show) {
  transform: translate(-100%);
}

.nart-burger-menu-container.show {
  transform: translate(0);
}

.nart-burger-menu-separator {
  background-color: #909090;
  height: 1px;
  width: auto;
}
