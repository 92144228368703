@font-face {
  font-family: 'Boing';
  src:
    local('Boing'),
    url(/assets/fonts/Boing-Regular.woff2) format('woff2'),
    url(/assets/fonts/Boing-Regular.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Boing';
  src:
    local('Boing'),
    url(/assets/fonts/Boing-Semibold.woff2) format('woff2'),
    url(/assets/fonts/Boing-Semibold.woff) format('woff');
  font-weight: bold;
  font-display: swap;
}

.no-scroll {
  overflow: hidden;
}

.transition {
  transition: 0.3s;
}
.transition-long {
  transition: 1s;
}
.transition-short {
  transition: 100ms;
}

.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 100;
}

.modal {
  overflow-y: auto;
}

.hoverable:hover {
  -webkit-box-shadow: 0px 10px 13px -7px;
  box-shadow: 0px 10px 13px -7px rgba(0, 0, 0, 0.2);
}
.hoverable-heavy:hover {
  -webkit-box-shadow: 0px 10px 13px 0px;
  box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.2);
}

.cover-zoom:hover img.hoverable {
  transform: scale(1.04);
}

.article-overlay {
  background-color: rgba(0, 0, 0, 0.45);
}

.rounded-medium {
  border-radius: 0.5em;
}
.wrap-on-return {
  white-space: pre-wrap;
}
.up-x-pix {
  position: relative;
  bottom: 4px;
}

.v-separator {
  width: 1px;
  height: auto;
  background: linear-gradient(
    to bottom,
    #e2e2e2 0%,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.v-separator-no-gradient {
  width: 1px;
  height: auto;
  background: #e2e2e2;
}
.v-separator-no-gradient-heavy {
  width: 2px;
  height: auto;
  background: #464646;
}
.h-separator {
  width: auto;
  height: 1px;
  background: linear-gradient(
    to right,
    #e2e2e2 0%,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.small-square-size {
  width: 50px;
  height: 50px;
}
.small-rectange-size {
  width: 50px;
  height: 65px;
}

.bb-shadow-hover {
  -webkit-box-shadow: 0px 10px 13px -7px;
  box-shadow: 0px 10px 13px -7px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.bb-s-shadow-hover {
  -webkit-box-shadow: 0px 5px 13px -9px;
  box-shadow: 0px 5px 13px -9px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
a .bb-shadow-hover:hover {
  -webkit-box-shadow: 0px 10px 13px -7px;
  box-shadow: 0px 14px 13px -7px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.nart-shadow {
  -webkit-box-shadow: 0px 2px 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  transition: 0.3s;
}
.nart-shadow:hover {
  -webkit-box-shadow: 0px 3px 5px;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.shadow-when-hovered:not(:hover) {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bg-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.btn-outline-success:hover {
  color: white !important;
}
.btn-outline-secondary:hover {
  color: white !important;
}
.bb-bordered {
  border: 1px solid;
}
.text-pink {
  color: #ff0080 !important;
}
.link-underline {
  text-decoration: underline;
}
.text-decoration-line-through {
  text-decoration: line-through;
}
.no-decoration {
  text-decoration: none !important;
}
.hover-decoration:hover {
  text-decoration: underline !important;
}
.bb-background-dark-to-light-grey {
  background: linear-gradient(to bottom, #303030 0%, #303030 80%, #f6f6f6 80%, #f6f6f6 100%);
}
.bb-background-light-grey-to-white {
  background: linear-gradient(to bottom, #f6f6f6 0%, #f6f6f6 85%, #ffffff 85%, #ffffff 100%);
}
.bb-background-light-grey {
  background-color: #f6f6f6;
}
.bb-background-grey {
  background-color: #e6e6e6;
}
.bb-background-dark-grey {
  background: linear-gradient(180deg, #202020 0%, #303030 100%);
}

.nart-background-grey {
  background-color: #f1f1f1;
}

.validated-green {
  color: $green-color;
}
.bb-border-green {
  border-color: $green-color;
}
.bb-background-green {
  background-color: $green-color;
}
.bb-border-primary-color {
  border-color: $primary-color;
}
.bb-text-gray-dark {
  color: $gray-900;
}

.bb-xs-text-size {
  font-size: 0.5rem;
}
.bb-s-text-size {
  font-size: 0.85rem;
}
.bb-regular-text-size {
  font-size: $font-size-base;
}
.bb-medium-text-size {
  font-size: 15px;
}
.bb-medium-small-text-size {
  font-size: 1.1rem;
}
.bb-medium-large-text-size {
  font-size: 1.15rem;
  line-height: 1.5rem;
}
.bb-l-text-size {
  font-size: 1.5rem;
}
.bb-text-500 {
  font-weight: 500;
}

.font-size-big {
  font-size: 1.2rem !important;
}
.bb-large-text-size {
  font-size: 1.7rem !important;
}
.bb-xl-text-size {
  font-size: 2rem;
}
.bb-xxl-text-size {
  font-size: 2.6rem;
}
.bb-3xl-text-size {
  font-size: 5rem;
}
@media (max-width: 768px) {
  .bb-xxl-text-size {
    font-size: 2rem;
  }
}
.overflow-hidden-2-lines {
  line-height: 1.35rem;
  height: 2.7rem;
  overflow: hidden;
}
.overflow-hidden-3-lines {
  line-height: 1.35rem;
  max-height: 4.05rem;
  overflow: hidden;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 900;
}

.basic-fade-enter {
  opacity: 0;
}
.basic-fade-enter-active {
  opacity: 1;
  transition: opacity 100ms;
}
.basic-fade-exit {
  opacity: 1;
}
.basic-fade-exit-active {
  opacity: 0;
  transition: opacity 100ms;
}

.bb-link-no-event {
  pointer-events: none;
}
.text-link {
  color: #787878;
  text-decoration: underline;
  background-color: transparent;
}

.bb-text-strike {
  text-decoration: line-through;
}
.star-and-text {
  width: 68px;
  text-align: center;
  background: white;
  padding: 3px;
}
.test-gray-light {
  color: #d5d5d5;
}

a .link-to-primary-color,
a.link-to-primary-color,
button .link-to-primary-color,
button:hover.link-to-primary-color {
  transition: 0.1s;
}

a:hover .link-to-primary-color,
a:hover.link-to-primary-color,
button:hover .link-to-primary-color,
button:hover.link-to-primary-color {
  color: #ff0080 !important;
}

.no-select {
  user-select: none;
}

.with-carriage-return {
  white-space: pre-line;
}

/*** Styles added to fix the issue with zoom in on iphone ***/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
