.nart-burger-menu-element-bar {
  position: relative;
  bottom: 5px;
  height: 4px;
  width: 110%;
  left: -5%;
}

.nart-burger-circle {
  height: 13px;
  width: 13px;
  position: relative;
  top: 4px;
  margin-right: 12px;
}
