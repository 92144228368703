.half-width {
  width: 49%;
}

.album-summary-small {
  max-height: 102px;
  overflow: hidden;
}

.album-header-carousel {
  overflow-y: scroll; /* do not set to `auto` to avoid flickering effect */
  overflow-x: hidden;
  max-height: 26rem;
}

.album-header-release-container {
  border-bottom-right-radius: 0.25rem !important;
  top: 0px;
  padding: 1px 4px 1px 4px;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .collection-array-picto {
    height: 1.7rem;
    width: 30px;
  }
}

.collection-array-picto {
  height: 1.7rem;
}

.dot {
  width: 12px;
  height: 12px;
  opacity: 0.5;
  border: 1px solid #787878;
}

.dot.selected {
  background-color: #787878;
  opacity: 1;
  -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
}

.current-print {
  background-color: #7aca2255;
}

.print-selection {
  border: 1px solid #78787855 !important;
  border-radius: 5px;
}

.youtube-thumbnail {
  background-color: #c4c4c4;
  height: 56px;
  overflow: hidden;
}

.youtube-thumbnail-cover {
  width: 100%;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
}

.youtube-thumbnail-play {
  position: absolute;
  left: 42%;
  top: 30%;
}
