.agenda-title-line-height {
  line-height: 3.8rem;
}
@media(max-width: 768px) {
  .agenda-title-line-height {
    line-height: 2.6rem;
  }
}

.agenda-time-chooser {
  background-color: #f6f6f6 !important;
  border: none !important;
}

.transparent-container {
  background-color: transparent !important;
  border: none !important;
}

.agenda-time-chooser-prepend {
  background-color: #f6f6f6 !important;
  border: none !important;
  max-width: 200px;
}
