.article-big-card {
  height: 15rem;
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
  background-image: url(/assets/placeholders/placeholder-article.webp);
  @media (min-width: 768px) {
    height: 28rem;
  }
}

.no-webp .article-big-card {
  background-image: url(/assets/placeholders/placeholder-article.jpg);
}

.article-big-card-rounded {
  border-radius: 0.5em;
  @media (max-width: 768px) {
    border-radius: 0rem;
  }
}

.article-big-card-title {
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.article-big-card-button {
  padding: 11px 28px 11px 28px !important;
  border-radius: 13px !important;
  font-weight: 500 !important;
  font-size: 1em !important;
  @media (max-width: 768px) {
    padding: 8px 18px 8px 18px !important;
  }
}

.article-big-card-button-caret {
  font-size: 0.7em;
}

.article-overlay-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 55%);
}
